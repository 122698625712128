body {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-snap-type: none;
  
}
html{
  height: 100%;
  margin: 0;
  padding: 0;
}
/* Kaydırma çubuğu stilini özelleştirme */
::-webkit-scrollbar {
  height: auto;
  box-sizing: border-box;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(110, 110, 110); /* Kaydırma çubuğu rengi */
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-image: radial-gradient(
    farthest-side at 73% 35%,
    transparent,
    rgba(174, 187, 240, 0.68)
  ); /* Kaydırma çubuğu yolu (arka plan) rengi */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  flex: 1;
  overflow-y: auto;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
@media (max-width: 768px) {
  html, body {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
  ::-webkit-scrollbar {
    width: 8px;
    display: none;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgb(110, 110, 110); /* Kaydırma çubuğu rengi */
    border-radius: 5px;
    display: none;
  }
  
  ::-webkit-scrollbar-track {
    display: none;
    background-image: radial-gradient(
      farthest-side at 73% 35%,
      transparent,
      rgba(174, 187, 240, 0.68)
    ); /* Kaydırma çubuğu yolu (arka plan) rengi */
  }
  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  body::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera */
  }
}
