a {
    text-decoration: none;
  }
  .App h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #333;
    direction: ltr;
    font-family: "Oswald";
    font-weight: 400;
    font-style: normal;
  }
  .App {
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
    background-image: radial-gradient(
      farthest-side at 73% 35%,
      transparent,
      rgba(171, 172, 177, 0.658)
    );
    user-select: none;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    background-color: #d9d4d4;
    min-height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
  .Content {
    display: flex;
    flex-direction: column;
  }
  .Slider {
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    color: antiquewhite;
  }
  .header {
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    min-height: 100vh;
  }
  .header h3 {
    font-weight: bold;
  
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-style: oblique;
    font-size: 55px;
    color: rgba(17, 17, 17, 0.74);
  }
  .header h5 {
    font-weight: bold;
    margin-top: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-style: oblique;
    font-size: 39px;
    color: rgba(17, 17, 17, 0.74);
  }
  .header h2 {
    font-size: x-large;
    color: rgba(17, 17, 17, 0.74);
    font-weight: bold;
    font-style: italic;
  }
  .homePage {
    display: flex;
    flex-direction: row;
    text-align: center;
    min-height: 100vh;
    top: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }
  .homePage img {
    margin: 10px 50px 10px 50px;
    border-radius: 10px;
    text-align: center;
    display: block;
  }
  .homeOptions {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
  }
  .buttons {
    align-items: center;
    display: block;
    margin: 3rem;
  }
  .Header {
    position: relative;
    display: fixed;
    align-items: center;
    color: rgba(25, 23, 23, 0.945);
    background-color: rgba(66, 65, 65, 0.233);
    width: 100%;
    height: 75px;
    font-weight: bold;
    list-style-type: none;
  }
  .logo img {
    transition: all 0.5s;
    width: 65px;
    cursor: pointer;
    border-radius: 10px;
    border: 3px inset #4ea6ed;
  }
  .logo img:hover {
    transform: scale(1.15);
    transition: all 0.5s;
  }
  .logo1 img {
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    width: 160px;
    cursor: pointer;
    border-radius: 10px;
    border: 3px inset #4ea6ed;
  }
  .logo1 img:hover {
    transform: scale(1.1);
    transition: all 0.5s;
  }
  .lists {
    height: 100%;
    align-items: center;
    justify-content: space-around;
    display: flex;
  }
  .listOne ul {
    flex-direction: row;
    display: flex;
  }
  .listOne ul li {
    transition: all 0.3s;
    user-select: none;
    font-size: large;
    margin: 0 1.3rem 0 1.3rem;
  }
  .listOne ul li:hover {
    transition: all 0.3s;
    color: #f9f9f9e5;
  }
  .listTwo ul {
    flex-direction: row;
    display: flex;
  }
  .listTwo ul li {
    transition: all 0.3s;
    user-select: none;
    font-size: large;
    margin: 0 1.3rem 0 1.3rem;
  }
  .listTwo ul li:hover {
    transition: all 0.3s;
    color: #f9f9f9;
  }
  .mainSlider {
    width: 100%;
    min-height: 550px;
    background-color: none;
    height: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    position: relative;
  }
  .slideImage svg {
    color: rgb(141, 141, 141);
    transition: all 0.5s;
  
    display: block;
    align-items: center;
  }
  .slideImage svg:hover {
    transition: all 0.5s;
    color: #000000a1;
  }
  
  .mainSlider img {
    width: 100%;
    height: 140%;
    object-fit: fill;
    
  }
  
  .Slider {
    width: 100%;
    height: 120%;
    
  }
  .image {
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
  
  .right-arrow {
    border-radius: 100px;
    background-color: #5a5a5a44;
    position: absolute;
    top: 350px;
    right: 32px;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  .left-arrow {
    border-radius: 100px;
    background-color: #5a5a5a44;
    position: absolute;
    top: 350px;
    left: 32px;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  .slide {
    opacity: 0;
    transition-duration: 500ms ease-in-out;
  }
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
  }
  
  .boxContainer {
    justify-content: center;
    flex-direction: row;
    display: flex;
  }
  .boxes:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.534); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
    color: #cccccc;
    transform: scale(1.1);
    transition: all 0.4s;
  }
  .boxes {
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(30, 33, 43, 0.39)
    );
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
    background-color: transparent;
    display: flex;
    flex-direction: column;
    margin: 55px 50px 25px 50px;
    transition: all 0.4s;
  }
  .titleBox {
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    text-align: center;
    align-items: center;
    display: flex;
    position: inherit;
    justify-content: center;
  }
  .promotion {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 370px;
    margin-top: 275px;
    margin-bottom: 50px;
    padding: 10px;
    transition: all 0.3s;
  }
  .promotion2 {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 370px;
    margin-top: 75px;
    margin-bottom: 50px;
    padding: 10px;
    transition: all 0.3s;
  }
  .promotion p {
    font-size: 22px;
    margin: 20px;
  }
  .promotionContent:hover {
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  .promotionContent {
    flex-direction: column;
    scale: 1.15;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(30, 33, 43, 0.39)
    );
    color: #1d1b1b;
    width: 55%;
    height: 80%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
  
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 20px;
    font-style: italic;
    font-weight: bold;
  }
  .promotionContent h2 {
    font-weight: bold;
    font-size: 36.5px;
  }
  .promotionContent h3 {
    position: inherit;
    margin: 5px;
    color: rgb(88, 89, 90);
    font-weight: bold;
    font-size: 40px;
  }
  .icons img {
    opacity: 0.7;
    margin: 20px 50px 20px 50px;
    padding: 20px 20px 20px 20px;
    width: 230px;
    height: 230px;
  }
  .services img {
   
    margin: 20px 50px 0px 50px;
    padding: 20px 0px 0px 20px;
    width: 270px;
    height: 330px;
  }
  
  .ourServices {
    justify-content: center;
    flex-direction: row;
    display: flex;
    margin-bottom: 30px;
  }
  .serviceText {
    margin-left: 70px;
    height: 40px;
    width: 250px;
    background-color: rgb(92, 90, 90);
  }
  .footer {
    width: 100%;
    flex-direction: column;
    position: relative;
    flex: 1;
    display: flex;
    bottom: 0;
  }
  .footerHead {
    display: flex;
    background-color: #1d1b1b;
    width: 100%;
    height: 350px;
    justify-content: row;
  }
  .footer h3 {
    color: #afc0cd;
  }
  .footerEnd {
    display: flex;
    color: #afc0cd;
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: center;
    background-color: rgba(25, 23, 23, 0.945);
  }
  .footerSections {
    color: #afc0cd;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
  }
  .footerBrowse {
    color: #afc0cd;
    margin: 10px;
  }
  .footerContactInfo {
    color: #afc0cd;
    margin: 20px;
  }
  .footerAd {
    max-width: 400px;
    color: #afc0cd;
    margin: 20px;
  }
  .footerIcon img {
    width: 150px;
    border-radius: 10px;
  }
  .footerIcon {
    margin-top: 60px;
    color: #3498db;
    align-items: center;
    margin-right: 150px;
  }
  .alti-uzun {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: whitesmoke;
    /* Metin rengi */
  }
  
  .alti-uzun::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px; /* Çizginin metinden biraz aşağıda olmasını sağlar */
    width: 100%;
    height: 2px; /* Çizgi kalınlığı */
    background-color: #3498db; /* Çizgi rengi */
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-in-out;
  }
  
  .alti-uzun:hover::after,
  .active::after {
    color: rgb(51, 68, 153);
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .AboutUsInfo {
    width: 100%;
    background-color: transparent;
    height: 575px;
    display: flex;
    justify-content: baseline;
  }
  .AboutUsInfoImg {
    border: 2px solid rgba(109, 109, 109, 0.89);
    display: flex;
    height: 450px;
    width: auto;
    margin: 30px;
    margin-left: 131px;
    border-radius: 8px;
  }
  .AboutUsInfoDescription {
    margin: 30px;
    width: auto;
    display: flex;
    flex-direction: column;
  }
  .AboutUsInfoDescription h2 {
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);
    display: flex;
    font-weight: bold;
    font-size: 35px;
    float: left;
  }
  .AboutUsInfoDescription p {
    width: 95%;
    line-height: 36.5px;
    display: flex;
    float: left;
    text-align: left;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
  }
  .ServicesInfo {
    margin-top: 125px;
    width: 100%;
    background-color: transparent;
    height: 550px;
    display: flex;
    justify-content: baseline;
  }
  .ServicesImg {
    border: 2px solid rgba(109, 109, 109, 0.89);
    display: flex;
    height: 450px;
    width: auto;
    margin: 30px;
    margin-left: 131px;
    border-radius: 8px;
  }
  .ServicesInfoDescription {
    text-align: center;
    margin: 30px;
    width: auto;
    display: flex;
    flex-direction: column;
  }
  .ServicesInfoDescription h3 {
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);
    display: flex;
    font-weight: bold;
    font-size: 35px;
    float: left;
  }
  .ServicesInfoDescription p {
    width: 95%;
    line-height: 36.5px;
    display: flex;
    float: left;
    text-align: left;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
  }
  .Services {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 420px;
    margin-top: 90px;
    margin-bottom: 20px;
    padding: 10px;
    transition: all 0.3s;
  }
  .Services p {
    margin: 10px;
  }
  .ServicesTitle:hover {
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  .ServicesTitle {
    flex-direction: column;
    scale: 1.15;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 55%;
    height: 80%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
  
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 20px;
    font-style: italic;
    font-weight: bold;
  }
  .ServicesTitle h2 {
    font-weight: bold;
    font-size: 36.5px;
  }
  .ServicesTitle h3 {
    position: inherit;
    margin: 5px;
    color: rgb(88, 89, 90);
    font-weight: bold;
    font-size: 40px;
  }
  .ServicesDetail {
    width: 100%;
    height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .ServicesCon {
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
  .ServicesCon h2 {
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);
    font-weight: bold;
    font-size: 40px;
    margin-left: 30px;
    display: flex;
    justify-content: center;
  }
  .ServicesCon ul {
    margin-top: 50px;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
  }
  .ServicesCon ul li {
    color: #1d1b1b;
    
    flex-direction: column;
    margin: 30px;
    font-size: 25px;
    border-radius: 5px;
   font-weight: bold;
  }
  .ServicesCon ul li img {
    border-radius: 5px;
    margin-bottom: 5px;
    border: 2px solid rgba(109, 109, 109, 0.89);
    width: 300px;
    height: 225px;
  }
  .ContactUs {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    margin-top: 70px;
    margin-bottom: 70px;
    padding: 10px;
    transition: all 0.3s;
  }
  .ContactUs p {
    margin: 10px;
  }
  .ContactUsContent {
    flex-direction: column;
    scale: 1.15;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(30, 33, 43, 0.39)
    );
    color: #1d1b1b;
    width: 55%;
    height: 80%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
  
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 30px;
    font-style: italic;
    font-weight: bold;
  }
  .ContactUsContent:hover {
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  .ContactUsMain{
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  
  }
  .ContactUsMain input{
    border: 1px solid #29292973;
    background-color: #81818148;
  }
  #messageInput{
    border-radius: 5px;
    border: 1px solid #29292973;
    background-color: #81818148;
    width: 500px;
    height: 85px;
    margin-top: 5px;
    margin-right:15px;
    margin-left: 10px;
    resize: none; /* Kullanıcıların boyutu değiştirmesini engellemek için */
      max-width: 100%; /* İçerik boyutu değiştiğinde genişliği otomatik olarak ayarlamak için */
   /* İçerik boyutu değişmeden önce başlangıç genişliği */
  }
  .ContactUsInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 100px;
    padding-bottom: 50px;
    
  }
  .ContactUsInfos{
    font-weight: bolder;
    color: #1d1b1b;
  }
  
  .ContactUsUser div{
    margin: 30px;
    
  
  }
  .ContactUsUser label{
    font-weight: bold;
    margin: 10px;  
    color: #1d1b1b;
  
  }
  
  .ContactUsUser input{
    margin-right: 8px;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .msgInput {
    margin : 8px;
  }
  .contactUsFormUsername{
    height: 60px;
  }
  .contactUsForm{
  border-radius:8px;
  border: 2px solid #818181;
  }
  .messageInput{
    border-radius: 5px;
    width: 500px;
    height: 100px;
  }
  .usernameInput{
    text-indent: 5px;
    width: 200px;
    height: 35px;
    margin-right:15px;
    margin-left: 10px;
  }
  .emailInput{
    text-indent: 5px;
    width: 300px;
    height: 35px;
  }
  .phoneInput{
    
    height: 35px;
  }
  .ContactUsButton{
    color: #1d1b1b;
    font-weight: bolder;
    font-size: large;
    width: 100px;
    height: 30px;
    border-radius: 6px;
    margin-bottom: 30px;
    border: 1px solid #29292973;
    background-color: #81818148;
  }
  .ContactUsIcon{
    flex-direction: column;
    display: flex;
    width: 150px;
    
    margin-top: 60px;
    color: #2279b3;
  }
  .ContactUsIcon img{
    border-radius: 10px;
  }
  
  .lblMesaj{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border-radius:8px;
  border: 2px solid #818181;
  }
  .lblMesaj textarea{
    text-indent: 5px;
    margin-bottom: 25px;
  
  }
  .bakimIletisim{
    color: red;
    font-weight: bold;
    text-decoration: underline;
  }
  .bakimSayfasi{ 
    margin-top: 50px;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: larger;
  }
  .fotokopibaslik{
    font-weight: bold;
    margin-top: 50px;
  }
  .FotokopiContent{
    height: 1100px;
  }
  .fotokopiAni{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 400px;
    margin-top: 50px;
  
    padding-bottom:80px;
    transition: all 0.3s;
  }
  
  .FotokopiTitle:hover {
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  .FotokopiTitle {
    flex-direction: column;
    scale: 1.10;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 55%;
    height: 65%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
  
    align-items: center;
    justify-content: center;
    display: flex;
    
    font-weight: bold;
  }
  .FotokopiTitle h2 {
    font-weight: bold;
    font-size: 33.5px;
  }
  .FotokopiTitle h3 {
    position: inherit;
    margin: 5px;
    color: rgb(88, 89, 90);
    font-weight: bold;
    font-size: 30px;
  }
  .markaTitle {
    justify-content: center;
    flex-direction: column;
    scale: 1.10;
    transition: all 0.3s;
    margin-top: -150px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 170px;
    height:90px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
  
    display: flex;
    font-size: 25px;
    font-weight: bold;
  }
  .markaTitle:hover {
    scale: 1.1;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  
  .markaKutu{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: stretch;
    
    
  }
  
  .markaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  
  .markaModel {
    text-align: left;
    width: 100%;
  }
  
  .markaModel h3 {
    margin: 35px 0 10px 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .markaModel ul {
    list-style: none;
    padding: 0;
  }
  
  .markaModel li {
    background-color: #ffffff1a;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
  .tonerContainer {
    margin-top: -230px;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  .tonerModel {
    color: #1d1b1b;
    text-align: center;
    width: 100%;
  }
  .tonerModel ul {
    list-style: none;
    padding: 0;
  }
  
  .tonerModel li {
    background-color: #ffffff1a;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
  .tonerModel p{
    color: #1d1b1b;
    font-weight: bold;
    font-size: 28px;
  }
  .tonerTitle{
    
    flex-direction: column;
    scale: 1.10;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 55%;
    height: 65%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
    font-size: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: bold;
  }
  .tonerTitle:hover {
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  .kiralamaTitle{
    
    flex-direction: column;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 55%;
    height: 65%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
    font-size: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: bold;
   
  }
  
  .kiralamaAni{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px;
    transition: all 0.3s;
  }
  .kiralamaContainer {
    margin: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  
  .kiralamaContainer2 {
    margin: 0px 5px 5px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  .kiralamaContainer3 {
    margin: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  .kiralamaContainer4 {
    margin: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  .kiralamaContainer5 {
    margin: 25px;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 40%;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  .kiralamaModel {
    color: #1d1b1b;
    text-align: center;
    width: 100%;
  }
  .kiralamaModel ul {
    list-style: none;
    padding: 0;
  }
  
  .kiralamaModel li {
    background-color: #ffffff1a;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
  .kiralamaModel p{
    color: #1d1b1b;
    font-weight: bold;
    font-size: 35px;
  }
  .kiralamaModel2 {
    color: #1d1b1b;
    text-align: center;
    width: 100%;
  }
  .kiralamaModel2 ul {
    list-style: none;
    padding: 0;
  }
  
  .kiralamaModel2 li {
    background-color: #ffffff1a;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
  .kiralamaModel2 p{
    color: #1d1b1b;
    font-weight: bold;
    font-size: 23px;
  }
  .kiralamaModel3 {
    color: #1d1b1b;
    text-align: center;
    width: 100%;
  }
  .kiralamaModel3 ul {
    list-style: none;
    padding: 0;
  }
  
  .kiralamaModel3 li {
    background-color: #ffffff1a;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
  .kiralamaModel3 p{
    color: #1d1b1b;
    font-weight: normal;
    font-size: 20px;
  }
  .kiralamaModel4 {
    color: #1d1b1b;
    text-align: center;
    width: 100%;
  }
  .kiralamaModel4 ul {
    list-style: none;
    padding: 0;
  }
  
  .kiralamaModel4 li {
    background-color: #ffffff1a;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
  .kiralamaModel4 p{
    color: #1d1b1b;
    font-weight: bold;
    font-size: 28px;
  }
  .kiralamaTitle{
    
    flex-direction: column;
    scale: 1.10;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 55%;
    height: 65%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
    font-size: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: bold;
  }
  .kiralamaTitle:hover {
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  .scrollLeftButton, .scrollRightButton{
    display: none;
  }
  
  
  .kiralamaKutu{
    
    position: inherit;
    display: flex;
    flex-direction: row;
    margin: 360px 0px 440px 0px;
  }
  .kiralamaModel5 {
    color: #1d1b1b;
    text-align: center;
    width: 100%;
  }
  .kiralamaModel5 ul {
    list-style: none;
    padding: 0;
  }
  
  .kiralamaModel5 li {
    
    position: relative;
    background-color: transparent;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
  }
  .kiralamaModel5 p{
    color: #1d1b1b;
    font-weight: bold;
   
  }
  
  .kiralamaModel5 li::before {
    content: '';
    position: absolute;
    left: 20px; /* Noktanın sol boşlukta görünmesi için */
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-color: rgba(17, 101, 196, 0.747);
    border-radius: 50%;
  }
  .TSTitle{
    flex-direction: column;
    scale: 1.15;
    transition: all 0.3s;
    margin-top: 10px;
    margin-bottom: 50px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 65%;
    height: 85%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
    align-items: center;
    justify-content: center;
    display: flex;
    font-style: italic;
    font-weight: bold;
  } 
  .TSTitle h2{
    font-size: 25px;
  }
  .TSAni{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    margin-top: 100px;
    margin-bottom: 120px;
    padding: 10px;
    transition: all 0.3s;
  }
  .printerAni{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 350px;
    margin-top: 50px;
   
    padding-bottom:100px;
    transition: all 0.3s;
  }
  .printerTitle {
   
    flex-direction: column;
    scale: 1.10;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 55%;
    height: 65%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
  
    align-items: center;
    justify-content: center;
    display: flex;
    
    font-weight: bold;
  }
  .printerTitle h2 {
    font-weight: bold;
    font-size: 33.5px;
  }
  .printerTitle h3 {
    position: inherit;
    margin: 5px;
    color: rgb(88, 89, 90);
    font-weight: bold;
    font-size: 30px;
  }
  .printerTitle:hover{
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  .printersContent{
    margin-bottom: 50px;
  }
  .tonerAni{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px;
    transition: all 0.3s;
  }
  html, body {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
  body {
    scroll-behavior: smooth;
    touch-action: pan-y;
  }
  /* Mobil Uyumlu Tasarım */
  @media (max-width: 768px) {
    /* Header Ayarları */
    html, body{
    height: 100%;
    margin: 0;
  }
  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  body::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera */
  }
    .header h3 {
      font-size: 24px;
    }
  
    .header h5 {
      font-size: 18px;
    }
  
    /* Liste Ayarları */
    .listOne ul, .listTwo ul {
      flex-direction: column;
      align-items: center;
      margin: 10px;
     
    }
    .scrollLeftButton, .scrollRightButton{
      display: flex;
    }
    .listOne ul li, .listTwo ul li {
      width: 135px;
      height: 30px;
      border: 1px solid #0d2a4169;
      background-color: #51617033;
      border-radius: 10px;
      margin: 2px 0;
      padding: 2px;
      list-style: none;
    }
    
    
    /* Promosyon ve Diğer Bölümler */
    .promotion, .promotion2, .Services, .ContactUs {
      flex-direction: column;
      padding: 20px;
      align-items: center;
      
    }
    .promotion{
      margin-top: 20px;
      margin-bottom: 170px;
    }
  
    .promotionContent, .ServicesTitle, .ContactUsContent {
      width: 80%;
      height: auto;
      padding: 5px;
      box-shadow: none;
      margin-top: 200px;
    }
    .promotionContent p{
      font-size: 19px;
    }
    .promotion{

    }
    .promotion2{
      margin-top: 0px;
      margin-bottom: 50px;
    }
  
    
  
  
    /* Footer Ayarları */
    .footerSections {
      flex-direction: column;
      align-items: center;
      height: auto;
      background-color: black;
      color: white;
      padding: 20px;
    }
  
    .footerBrowse, .footerContactInfo, .footerAd {
      width: 100%;
      margin: 10px 0;
      text-align: center;
    }
    .titleBox{
      margin-top: 35px;
}
    .footerBrowse ul, .footerContactInfo ul, .footerAd ul {
      padding: 0;
    }
  
    .footerBrowse ul li, .footerContactInfo ul li, .footerAd ul li {
      list-style: none;
      margin: 5px 0;
    }
  
    .footerIcon {
      margin: 10px;
    }
  .footer{
    flex-shrink: 0;
  }
    /* Slayt ve Resim Ayarları */
    .mainSlider, .image {
      display: inline-block;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height: 230px;
    }
  
    .right-arrow{
      top: 45%;
      right: 0;
      transform: translateY(-50%);
      font-size: 5px;
      padding: 10px;
    }
    .left-arrow {
      top: 45%;
      left: 0;
      transform: translateY(-50%);
      font-size: 5px;
      padding: 10px;
    }
  
    /* Resim ve Logo Ayarları */
    .homePage img, .icons img, .services img {
      width: 90%;
      margin: 10px 0;
    }
    .icons img{
      min-width: 90px;
      height: 90px;
      max-width: 100px;
      max-height: 100px;
      margin: 0px -13px -15px 0px;
    }
  
  
    .logo img, .logo1 img {
      width: 50px;
      margin: 5px;
      border: 2px inset #4ea6ed;
    }
  
    .logo1 img {
      width: 80px;
    }
  
    .footerBrowse ul li a, .footerContactInfo ul li a, .footerAd ul li a {
      font-size: 14px;
    }
  
    .footerBrowse h3, .footerContactInfo h3, .footerAd h3 {
      font-size: 18px;
    }
  
  a {
    text-decoration: none;
  }
  .App h1,
  h2,
  h3,
  h4,
  h5,
  h6 {

    color: #333;
    direction: ltr;
    font-family: "Oswald";
    font-weight: 400;
    font-style: normal;
  }
  
  .content {
    flex: 1; /* İçerik alanının büyüyerek footer'ı en alta itmesini sağlar */
  }
  .App {
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
    background-image: radial-gradient(
      farthest-side at 73% 35%,
      transparent,
      rgba(171, 172, 177, 0.658)
    );
    user-select: none;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    background-color: #d9d4d4;
    min-height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
  .Content {
    display: flex;
    flex-direction: column;
  }
  .Slider {
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    color: antiquewhite;
  }
  .header {
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    min-height: 100vh;
  }
  
  .header h2 {
    font-size: x-large;
    color: rgba(17, 17, 17, 0.74);
    font-weight: bold;
    font-style: italic;
  }
  
  .homePage {
    display: flex;
    flex-direction: row;
    text-align: center;
    min-height: 100vh;
    top: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }
  .homePage img {
    margin: 10px 50px 10px 50px;
    border-radius: 10px;
    text-align: center;
    display: block;
  }
  .homeOptions {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
  }
  .buttons {
    align-items: center;
    display: block;
    margin: 3rem;
  }
  .Header {
    position: relative;
    display: fixed;
    align-items: center;
    color: rgba(25, 23, 23, 0.945);
    background-color: rgba(66, 65, 65, 0.233);
    width: 100%;
    height: 75px;
    font-weight: bold;
    list-style-type: none;
  }
  
  .image {
    left: 0;
    right: 0;
    position: relative;
    object-fit: cover;
  }
  .boxContainer {
    justify-content: center;
    flex-direction: row;
    display: flex;
  }
  .boxes:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.534); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
    color: #cccccc;
    transform: scale(1.1);
    transition: all 0.4s;
  }
  .boxes {
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(30, 33, 43, 0.39)
    );
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
    background-color: transparent;
    display: flex;
    flex-direction: column;
    margin: 55px 50px 25px 50px;
    transition: all 0.4s;
  }
  .titleBox {
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    text-align: center;
    align-items: center;
    display: flex;
    position: inherit;
    justify-content: center;
    margin-bottom: 20px;
  }
  .scrollableContainer {
    position: relative;
    margin-bottom: 30px;
  }
  
  .scrollList {
    overflow-x: scroll;
    scroll-behavior: smooth;
    padding: 0 10px;
  }
  .scrollList img {
    max-width: 150px;
    max-height: 150px; 
    border-radius: 10px; 
    margin-left: 5px;
  }
  
  .arrow-buttons {
    display: block;
  }
  .scrollLeftButton, .scrollRightButton {
    display: block; /* Show buttons on mobile */
    position: absolute;
    top: 45%;
    transform: translateY(-30%);
    background: none;
    border: none;
    cursor: pointer;
    z-index: 10;
    border-radius: 20px;
    background-color: #2c2c2c2a;
    transition: 0.3s;
  }
  .scrollLeftButton:hover, .scrollRightButton:hover {
    background-color: #14141483;
    transition: 0.3s;
  }
  
  .scrollLeftButton {
    left: 0px;
  }
  
  .scrollRightButton {
    right: 0px;
  }
  
  .services img {
   
    margin: 20px 50px 0px 50px;
    padding: 20px 0px 0px 20px;
    width: 270px;
    height: 330px;
  }
  
  .ourServices {
    justify-content: center;
    flex-direction: row;
    display: flex;
    margin-bottom: 30px;
  }
  .serviceText {
    margin-left: 70px;
    height: 40px;
    width: 250px;
    background-color: rgb(92, 90, 90);
  }
  .footer {
    width: 100%;
    flex-direction: column;
    position: relative;
    flex: 1;
    display: flex;
    bottom: 0;
  }
  .footerHead {
    display: flex;
    background-color: #1d1b1b;
    width: 100%;
    height: 350px;
    justify-content: row;
  }
  .footer h3 {
    color: #afc0cd;
  }
  .footerEnd {
    display: flex;
    color: #afc0cd;
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: center;
    background-color: rgba(25, 23, 23, 0.945);
    bottom:0;
    margin-bottom: 0;
    display: none;
  }
  .footerSections {
    align-items: center;
    color: #afc0cd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
  }
  .footerBrowse {
    color: #afc0cd;
    margin: 10px;
  }
  .footerContactInfo {
    
    color: #afc0cd;
    margin: 20px;
  }
  .footerAd {
    max-width: 400px;
    color: #afc0cd;
    margin: 20px;
  }
  .footerIcon img {
    width: 150px;
    border-radius: 10px;
  }
  .footerIcon {
    margin-top: 60px;
    color: #3498db;
    align-items: center;
    
  }
  .alti-uzun {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: whitesmoke;
    /* Metin rengi */
  }
  
  .alti-uzun::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px; /* Çizginin metinden biraz aşağıda olmasını sağlar */
    width: 100%;
    height: 2px; /* Çizgi kalınlığı */
    background-color: #3498db; /* Çizgi rengi */
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-in-out;
  }
  
  .alti-uzun:hover::after,
  .active::after {
    color: rgb(51, 68, 153);
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .AboutUsInfo {
    margin-top: 50px;
    width: 100%;
    background-color: transparent;
    justify-content: center;
    display: inline;
    flex-direction: row;
  }
  .AboutUsInfoImg {
    clip-path: polygon(0% 10%, 100% 0%, 100% 90%, 0% 100%);
    object-fit: cover;
    margin-top: 150px;
    border: none;
    display: flex;
    left: 0;
    margin-left: 0px;
    width: 100%;
    position: inherit;
    border-radius: 0px;
    opacity: 75%;
  }
  .AboutUsInfoDescription {
    margin: 30px;
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .AboutUsInfoDescription h2 {
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);
    display: flex;
    font-weight: bold;
    font-size: 35px;
    float: left;
  }
  .AboutUsInfoDescription p {
    width: 95%;
    line-height: 36.5px;
    display: flex;
    float: left;
    text-align: left;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
  }
 
  .ServicesInfoDescription {
    text-align: center;
    margin: 30px;
    width: auto;
    display: flex;
    flex-direction: column;
  }
  .ServicesInfoDescription h3 {
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);
    display: flex;
    font-weight: bold;
    font-size: 35px;
    float: left;
  }
  .ServicesInfoDescription p {
    width: 95%;
    line-height: 36.5px;
    display: flex;
    float: left;
    text-align: left;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
  }
  .Services {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 90px;
    margin-bottom: 20px;
    padding: 10px;
    transition: all 0.3s;
  }
  .Services p {
    margin: 10px;
  }
  .ServicesTitle:hover {
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  .ServicesTitle {
    flex-direction: column;
    scale: 1.15;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 80%;
    height: auto;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
  
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
  }
  .ServicesTitle h2 {
    font-weight: bold;
    font-size: 28.5px;
  }
  .ServicesTitle h3 {
    position: inherit;
    margin: 5px;
    color: rgb(88, 89, 90);
    font-weight: bold;
    font-size: 30px;
  }
  .ServicesDetail {
    width: 100%;
    height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ServicesInfo {
  
    width: 100%;
    background-color: transparent;
    justify-content: center;
    display: inline;
    flex-direction: row;
    
  }

  .ServicesImg{
    clip-path: polygon(0% 10%, 100% 0%, 100% 90%, 0% 100%);
    object-fit: cover;
    margin-top: 80px;
    border: none;
    display: flex;
    left: 0;
    margin-left: 0px;
    width: 100%;
    position: inherit;
    border-radius: 0px;
    opacity: 75%;
  }
  .ServicesCon {
    margin-top: 50px;
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
  .ServicesCon h2 {
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);
    font-weight: bold;
    font-size: 30px;
    margin-left: 30px;
    display: flex;
    justify-content: center;
  }
  .ServicesCon ul {
    margin-top: 50px;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
  }
  .ServicesCon ul li {
    color: #1d1b1b;
    
    flex-direction: column;
    margin: 30px;
    font-size: 25px;
    border-radius: 5px;
   font-weight: bold;
  }
  .ServicesCon ul li img {
    border-radius: 5px;
    margin-bottom: 5px;
    border: 2px solid rgba(109, 109, 109, 0.89);
    width: 300px;
    height: 225px;
  }
  .ContactUs {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    margin-top: 70px;
    margin-bottom: 70px;
    padding: 10px;
    transition: all 0.3s;
  }
  .ContactUs p {
    margin: 10px;
  }
  .ContactUsContent {
    flex-direction: column;
    scale: 1.15;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(30, 33, 43, 0.39)
    );
    color: #1d1b1b;
    width: 55%;
    height: 80%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
  
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 30px;
    font-style: italic;
    font-weight: bold;
  }
  .ContactUsContent:hover {
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  .ContactUsMain{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  }
  .ContactUsMain input{
    border: 1px solid #29292973;
    background-color: #81818148;
  }
  #messageInput{
    border-radius: 5px;
    border: 1px solid #29292973;
    background-color: #81818148;
    width: 300px;
    height: 85px;
    margin-top: 5px;
    margin-right:15px;
    margin-left: 10px;
    resize: none; /* Kullanıcıların boyutu değiştirmesini engellemek için */
      max-width: 100%; /* İçerik boyutu değiştiğinde genişliği otomatik olarak ayarlamak için */
   /* İçerik boyutu değişmeden önce başlangıç genişliği */
  }
  .ContactUsInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
    padding-bottom: 30px;
    
  }
  .ContactUsInfos{
    font-weight: bolder;
    color: #1d1b1b;
  }
  
  .ContactUsUser div{
    margin: 30px;
    
  
  }
  .ContactUsUser label{
    font-weight: bold;
    margin: 10px;  
    color: #1d1b1b;
  
  }
  
  .ContactUsUser input{
    margin-right: 8px;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .msgInput {
    margin : 8px;
  }
  .contactUsFormUsername{
    height: 60px;
  }
  .contactUsForm{
  border-radius:8px;
  border: 2px solid #818181;
  }
  .messageInput{
    border-radius: 5px;
    width: 500px;
    height: 100px;
  }
  .usernameInput{
    text-indent: 5px;
    width: 200px;
    height: 35px;
    margin-right:15px;
    margin-left: 10px;
  }
  .emailInput{
    text-indent: 5px;
    width: 300px;
    height: 35px;
  }
  .phoneInput{
    
    height: 35px;
  }
  .ContactUsButton{
    color: #1d1b1b;
    font-weight: bolder;
    font-size: large;
    width: 100px;
    height: 30px;
    border-radius: 6px;
    margin-bottom: 30px;
    border: 1px solid #29292973;
    background-color: #81818148;
  }
  .ContactUsIcon{
    flex-direction: column;
    display: flex;
    width: 150px;
    
    margin-top: 60px;
    color: #2279b3;
  }
  .ContactUsIcon img{
    border-radius: 10px;
  }
  
  .lblMesaj{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border-radius:8px;
  border: 2px solid #818181;
  }
  .lblMesaj textarea{
    text-indent: 5px;
    margin-bottom: 25px;
  
  }
  .bakimIletisim{
    color: red;
    font-weight: bold;
    text-decoration: underline;
  }
  .bakimSayfasi{ 
    margin-top: 50px;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: larger;
  }
  .Fotokopi{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .fotokopibaslik{
    font-weight: bold;
    margin-top: 50px;
  }
  .FotokopiContent{
    flex:1;
  }
  .fotokopiAni{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding-bottom:40px;
    transition: all 0.3s;
  }
  
  .FotokopiTitle:hover {
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  .FotokopiTitle {
    flex-direction: column;
    scale: 1.10;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 75%;
    height: 65%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
    margin-bottom: 120px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px;
    font-weight: bold;
  }
  .FotokopiTitle h2 {
    font-weight: bold;
    font-size: 28.5px;
  }
  .FotokopiTitle h3 {
    position: inherit;
    margin: 5px;
    color: rgb(88, 89, 90);
    font-weight: bold;
    font-size: 30px;
  }
  .markaTitle {
    justify-content: center;
    flex-direction: column;
    scale: 1.10;
    transition: all 0.3s;
    margin-top: -150px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 170px;
    height:90px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
  
    display: flex;
    font-size: 25px;
    font-weight: bold;
  }
  .markaTitle:hover {
    scale: 1.1;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  
  .markaKutu{
    display: flex;
    flex-direction: column;
    gap: 150px;
    align-items: stretch;
    
    
  }
  
  .markaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  
  .markaModel {
    text-align: left;
    width: 100%;
  }
  
  .markaModel h3 {
    margin: 35px 0 10px 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .markaModel ul {
    list-style: none;
    padding: 0;
  }
  
  .markaModel li {
    background-color: #ffffff1a;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
  .Toner{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .TonerContent{
    flex:1;
  }
  .tonerContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: auto;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    margin-bottom: 60px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  .tonerModel {
    color: #1d1b1b;
    text-align: center;
    width: 100%;
  }
  .tonerModel ul {
    list-style: none;
    padding: 0;
  }
  
  .tonerModel li {
    background-color: #ffffff1a;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
  .tonerModel p{
    color: #1d1b1b;
    font-weight: bold;
    font-size: 26px;
  }
  .tonerTitle{
    
    flex-direction: column;
    scale: 1.10;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 75%;
    height: 65%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
    font-size: 28px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: bold;
  }
  .tonerTitle:hover {
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }

  .kiralamaTitle h2{
    font-size: 28px;
  }
  
  .kiralamaAni{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    margin-top: 50px;
    margin-bottom: 0px;
    transition: all 0.3s;
  }
  .kiralamaContainer {
    margin: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  
  .kiralamaContainer2 {
    margin: 0px 5px 5px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  .kiralamaContainer3 {
    margin: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  .kiralamaContainer4 {
    margin: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  .kiralamaContainer5 {
    margin: 25px;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 70%;
    border-radius: 10px;
    background: radial-gradient(farthest-side at 37% 75%, transparent, rgba(233, 205, 205, 0.384));
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171);
    padding: 20px;
    transition: all 0.3s;
    transform-style: preserve-3d;
  }
  .kiralamaModel {
    color: #1d1b1b;
    text-align: center;
    width: 100%;
  }
  .kiralamaModel ul {
    list-style: none;
    padding: 0;
  }
  
  .kiralamaModel li {
    background-color: #ffffff1a;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
  .kiralamaModel p{
    color: #1d1b1b;
    font-weight: bold;
    font-size: 35px;
  }
  .kiralamaModel2 {
    color: #1d1b1b;
    text-align: center;
    width: 100%;
  }
  .kiralamaModel2 ul {
    list-style: none;
    padding: 0;
  }
  
  .kiralamaModel2 li {
    background-color: #ffffff1a;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
  .kiralamaModel2 p{
    color: #1d1b1b;
    font-weight: bold;
    font-size: 23px;
  }
  .kiralamaModel3 {
    color: #1d1b1b;
    text-align: center;
    width: 100%;
  }
  .kiralamaModel3 ul {
    list-style: none;
    padding: 0;
  }
  
  .kiralamaModel3 li {
    background-color: #ffffff1a;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
  .kiralamaModel3 p{
    color: #1d1b1b;
    font-weight: normal;
    font-size: 20px;
  }
  .kiralamaModel4 {
    color: #1d1b1b;
    text-align: center;
    width: 100%;
  }
  .kiralamaModel4 ul {
    list-style: none;
    padding: 0;
  }
  
  .kiralamaModel4 li {
    background-color: #ffffff1a;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
  .kiralamaModel4 p{
    color: #1d1b1b;
    font-weight: bold;
    font-size: 28px;
  }
  .kiralamaTitle{
    
    flex-direction: column;
    scale: 1.10;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 75%;
    height: 75%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: bold;
  }
  .kiralamaTitle:hover {
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  
  .kiralamaKutu{
    
    position: inherit;
    display: flex;
    flex-direction: row;
    margin: 20px 0px 0px 0px;
  }
  .kiralamaModel5 {
    color: #1d1b1b;
    text-align: center;
    width: 100%;
  }
  .kiralamaModel5 ul {
    list-style: none;
    padding: 0;
  }
  
  .kiralamaModel5 li {
    
    position: relative;
    background-color: transparent;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
  }
  .kiralamaModel5 p{
    color: #1d1b1b;
    font-weight: bold;
  }
  
  .kiralamaModel5 li::before {
    content: '';
    position: absolute;
    left: 42%;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    width: 30px;
    height: 5px;
    background-color: rgba(17, 101, 196, 0.747);
    border-radius: 50%;
  }
  .TSTitle{
    flex-direction: column;
    scale: 1.15;
    transition: all 0.3s;
    margin-top: 50px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 85%;
    height: auto;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px;
    line-height: 200%;
    font-style: normal;
    font-weight: bold;
  } 
  .TSTitle h2{
    font-size: 20px;
  }
  .TSAni{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    margin-top: 100px;
    margin-bottom: 120px;
    padding: 10px;
    transition: all 0.3s;
  }
  .printerAni{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 350px;
    margin-top: 50px;
   
    padding-bottom:100px;
    transition: all 0.3s;
  }
  .printerTitle {
   
    flex-direction: column;
    scale: 1.10;
    transition: all 0.3s;
    margin-top: 10px;
    border-radius: 10px;
    background-image: radial-gradient(
      farthest-side at 37% 75%,
      transparent,
      rgba(233, 205, 205, 0.384)
    );
    color: #1d1b1b;
    width: 80%;
    height: 55%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.171); /* 3D kutu efekti için gölge ekliyoruz */
    transform-style: preserve-3d; /* 3D dönüşüm stilini koruyoruz */
    transform: translateZ(
      50px
    ); /* Yazıyı kutunun üstünde yukarı doğru hareket ettiriyoruz */
  
    align-items: center;
    justify-content: center;
    display: flex;
    
    font-weight: bold;
  }
  .printerTitle h2 {
    font-weight: bold;
    font-size: 27.5px;
  }
  .printerTitle h3 {
    position: inherit;
    margin: 5px;
    color: rgb(88, 89, 90);
    font-weight: bold;
    font-size: 30px;
  }
  .printerTitle:hover{
    scale: 1.2;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.45);
  }
  .printersContent{
    margin-bottom: 50px;
  }
  .tonerAni{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px;
    transition: all 0.3s;
  }
  }